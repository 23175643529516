import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyService } from '../../services/currency.service';

/**
 * This custom pipe calculates and the price in the current
 * currency set by the user (or default currency ).
 */
@Pipe({
    name: 'currencySpace',
    pure: false,
    standalone: false
})
export class CurrencySpacePipe implements PipeTransform {

  constructor(
    private currencyService: CurrencyService
  ) { }

  public transform(value: number, defaultCurrency?: boolean): string {
    let currency = this.currencyService.currentCurrency;
    let price: string;

    /*TODO: this is to display the value in default currency whereever we need it
      without displaying twice if the current currency is the default one. BUT its NOT the most beautiful code ever.*/
    if (defaultCurrency) {
      if (this.currencyService.currencyChanged()) {
        currency = this.currencyService.defaultCurrency;
        price = this.currencyService.calculatePrice(value, currency).toFixed(2);
        return this.displayPrice(currency, price);
      } else {
        return '';
      }
    }

    price = this.currencyService.calculatePrice(value, currency).toFixed(2);
    return this.displayPrice(currency, price);
  }

  private displayPrice(currency: string, price: string): string {
    if (currency === 'USD') {
      currency = '$';
    } else if (currency === 'EUR') {
      currency = '€';
    } else if (currency === 'GBP') {
      currency = '£';
    } else if (currency === 'MXN') {
      currency = 'Peso';
    } else if (currency === 'ZAR') {
      currency = 'R';
    } else if (currency === 'PKR') {
      currency = 'Rs';
    }
    return currency + ' ' + price;
  }
}
