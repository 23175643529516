{
  "ACTIONS": {
    "CONFIRM": "تأكيد",
    "SUBMIT": "حفظ",
    "CANCEL": "إلغاء",
    "YES": "نعم",
    "NO": "لا",
    "EMPTY": "لا يوجد لديك طلبات",
    "CLEAR_ALL": "مسح الخيارات",
    "AWESOME": "رائع",
    "VIEW_RECEIPT": "الفاتورة",
    "ADD_CART": "أضف إلى السلة",
    "SEARCH_PLACEHOLDER": "ابحث عن أكلات أو مطاعم"
  },
  "COMMON": {
    "CALORIES": "Cal {{calories}}",
    "CHOOSE_MODIFIERS": "{{min}} إلى {{max}} خيارات",
    "FEATURE_UNAVAILABLE": "هذه الخاصية غير متوفرة الأن",
    "MISSING_SELECTIONS": "يرجى إكمال تعبئة الخيارات قبل المتابعة",
    "ITEM_UNAVAILABLE": "عذراً، هذا المنتج غير متوفر حالياً",
    "REQUIRED": "إلزامي",
    "FEES": "الرسوم الاضافية",
    "MIN_ORDER_ERROR": "عليك الوصول لقيمة الحد الأدنى {{currency}} {{amount}} للطلب ",
    "ADD_MIN_ORDER": "أضف {{amount}} {{currency}} كي تستطيع أن تتقدم بالطلب"
  },
  "LANGUAGES": {
    "EN": "English",
    "AR": "عربي",
    "FA": "فارسي",
    "HI": "हिन्दी",
    "RU": "русский",
    "DE": "Deutsch",
    "FR": "Francais",
    "TH": "ไทย",
    "EL": "Ελληνικά",
    "CNR": "Crnogorski",
    "ZH-HANS": "中文",
    "RO": "Română",
    "HU": "Magyar",
    "BG": "български",
    "TR": "Türk",
    "ES": "Español"
  },
  "BILL": {
    "TITLE": "كيف تريد",
    "TOTAL": "المجموع",
    "NAME": "اسم العائلة",
    "ROOM_NUMBER": "رقم الغرفة",
    "SNACKBAR_TITLE": "سنكون معك قريباً",
    "SNACKBAR_OK": "فهمت عليك",
    "DISCOUNT": "خصومات أو بطاقة",
    "PAYMENT_OPTIONS": "خيارات الدفع",
    "PAY_WITH": "ادفع عن طريق",
    "PAY_BILL": "ادفع فاتورة"
  },
  "CART": {
    "TITLE": "بطاقة",
    "TOTAL": "المجموع",
    "CURRENT_ORDER": "الطلب الحالي",
    "ITEMS": "عناصر",
    "ITEMS_NUMBER": "{{عدد}} العناصر",
    "VIEW": "عرض البطاقة والتقدم بالطلب",
    "CLOSE": "إغلاق البطاقة",
    "EMPTY": "فارغة",
    "SPECIAL_INSTRUCTION": "تعليمات خاصة (char {{charMax}})",
    "PLACE_ORDER": "تقدم بطلب",
    "OOPS": "يا ساتر",
    "DONE": "طلبك سيكون عندك قريبا",
    "GOT_IT": "فهمت عليك",
    "ITEM_LINE": "{{name}}",
    "OOPS_DESC": "بسبب هذا الطلب الذي طلبت تعديلة ,  نرجو منك إلغاء الطلب من البطاقة , شكرا",
    "CONFIRM_CLEAR": "هل أنت متأكد؟",
    "CLEAR": "مسح البطاقة",
    "ITEM_COMMENT": "أضف تعليمات خاصة"
  },
  "PAYMENT": {
    "CONFIRMED": "تم دفع فاتورة الطلب بنجاح",
    "ERROR": "حصل خطأ خلال عملية الدفع، يرجى المحاولة مرة أخرى",
    "CHOOSE_METHOD": "إختر طريقة الدفع",
    "PAY_CARD": "إدفع",
    "SUCCESSFUL": "تم دفع الفاتورة بنجاح",
    "SUMMARY": "الفاتورة",
    "PROGRESS": "الرجاء الانتظار حتى تتم عملية الدفع...",
    "CANCEL": "الرجاء عدم إعادة تحميل الصفحة أو الرجوع. إن أردت أن تلغي العملية، يرجى الضغط على زر الالغاء الموجود على ماكينات الدفع."
  },
  "ORDER": {
    "TITLE": "طلباتي",
    "CONFIRMED": "تم",
    "NOTES": "أضف ملاحظات",
    "TOTAL": "المجموع",
    "SUBTOTAL": "المجموع الفرعي",
    "ORDER": "طلب",
    "PAID": "مدفوع",
    "PLACED": "طلبك قد وضع بنجاح",
    "ORDERS": "طلبات",
    "PREVIOUS": "الطلبات السابقة",
    "REJECTED": "عذراً، بعض الصحون التي تود طلبها غير موجودة حالياً وسيتم الغائها من الطلبية"
  },
  "WAITER": {
    "SNACKBAR_TITLE": "سنكون معك قريباً",
    "SNACKBAR_OK": "فهمت عليك",
    "HELP": "كيف يمكن ان نساعد؟",
    "ADDITIONAL_COMMENTS": "ملاحظات"
  },
  "MENU": {
    "MENU": "الرئيسية",
    "SEARCH": "بحث",
    "STAFF": "نادل",
    "ORDERS": "طلباتي",
    "BILL": "اطلب الفاتورة",
    "CONCIERGE": "كونسيرج",
    "DIRECTORY": "الدليل",
    "SETTINGS": "لغة",
    "FILTERS": "التصفيات الغذائية",
    "BRANDS": "مطاعم",
    "SECTIONS": "أقسام"
  },
  "SETTINGS": {
    "TITLE": "الإعدادات",
    "LANGUAGE": "اللغة",
    "CURRENCY": "العملة"
  },
  "HOTEL_PREFERENCE": {
    "TITLE": "رجاء تحديد خياراتكم",
    "ROOM": "أضف لحساب الغرفة",
    "DELIVERY": "الدفع عند الاستلام",
    "NUMBER_OF_GUESTS": "عدد الضيوف"
  },
  "RESCAN": {
    "TITLE": "QR",
    "ACTION": "QR يرجى إعادة مسح اللمواصلة جلستك",
    "RESCAN": "إعادة المسح",
    "SCANNING": "إعادة المسح جارية ..."
  },
  "PICKUP": {
    "TITLE": "تفاصيلك"
  },
  "VOUCHER": {
    "ADD": "أضف قسيمة خصم",
    "VOUCHER": "الخصم",
    "DISCOUNTED_AMOUNT": "المجموع بعد الخصم",
    "CURRENT_BALANCE": "الرصيد الحالي",
    "REMAINING_BALANCE": "الرصيد المتبقي",
    "AMOUNT_DUE": "المجموع المستحق",
    "WARNING": "يمكنك إستخدام قسيمة واحدة للفاتورة"
  }
}
