import { Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-font-noto',
    templateUrl: './noto.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./noto.component.scss'],
    standalone: false
})
export class FontNotoComponent {

  constructor() {
    document.body.style.setProperty('font-family', 'Noto Sans SC, sans-serif');
  }
}
