{
  "ACTIONS": {
    "CONFIRM": "Confirmar",
    "SUBMIT": "Enviar",
    "CANCEL": "Cancelar",
    "YES": "Sí",
    "NO": "No",
    "EMPTY": "No tienes ningún pedido",
    "CLEAR_ALL": "Borrar Todo",
    "AWESOME": "Awesome!",
    "VIEW_RECEIPT": "The Receipt",
    "ADD_CART": "Add to Cart",
    "SEARCH_PLACEHOLDER": "Search for food or restaurants"
  },
    "LANGUAGES": {
    "EN": "English",
    "AR": "عربى",
    "FA": "فارسی",
    "HI": "हिन्दी",
    "RU": "русский",
    "DE": "Deutsch",
    "FR": "Francais",
    "TH": "ไทย",
    "EL": "Ελληνικά",
    "CNR": "Crnogorski",
    "ZH-HANS": "中文",
    "RO": "Română",
    "BG": "български",
    "ES": "Español"
  },
  "COMMON": {
    "CALORIES": "{{calories}} Cal",
    "CHOOSE_MODIFIERS": "Elige de {{min}} a {{max}}",
    "FEATURE_UNAVAILABLE": "Función no disponible actualmente",
    "MISSING_SELECTIONS": "Faltan algunas opciones. Por favor, selecciona antes de continuar",
    "ITEM_UNAVAILABLE": "This item is currently not available",
    "REQUIRED": "Required",
    "FEES": "Fees",
    "MIN_ORDER_ERROR": "Minimum order value of {{currency}} {{amount}} is required to place your order",
    "ADD_MIN_ORDER": "Add {{currency}} {{amount}} to place your order"
  },
  "BILL": {
    "TITLE": "¿Cómo te gustaría pagar?",
    "TOTAL": "Total:",
    "NAME": "Apellido",
    "ROOM_NUMBER": "Número de Habitación",
    "SNACKBAR_TITLE": "Tu factura está en camino",
    "SNACKBAR_OK": "Entendido",
    "DISCOUNT": "Oferta o Tarjeta de Descuento",
    "PAYMENT_OPTIONS": "Formas de Pago",
    "PAY_WITH": "Pagar con",
    "PAY_BILL": "Pagar la factura"
  },
  "CART": {
    "TITLE": "Carrito",
    "TOTAL": "Total",
    "CURRENT_ORDER": "Pedido Actual",
    "ITEMS": "Artículos",
    "ITEMS_NUMBER": "{{count}} artículos",
    "VIEW": "Ver carrito & Hacer pedido",
    "CLOSE": "Cerrar carrito",
    "EMPTY": "Vacío",
    "SPECIAL_INSTRUCTION": "Instrucción especial ({{charMax}} char)",
    "PLACE_ORDER": "Hacer pedido",
    "OOPS": "Oops",
    "DONE": "Tu pedido estará contigo muy pronto",
    "GOT_IT": "Entendido",
    "ITEM_LINE": "{{name}}",
    "OOPS_DESC": "Debido a que el artículo que intentas modificar tiene algunos modificadores adjuntos, por favor, retíralo del carrito. ¡Gracias!",
    "CONFIRM_CLEAR": "Are you sure you want to clear the cart?",
    "CLEAR": "Clear Cart",
    "ITEM_COMMENT": "ADD SPECIAL INSTRUCTIONS"
  },
  "PAYMENT": {
    "CONFIRMED": "¡Tu pedido ha sido procesado!",
    "ERROR": "Se ha producido un error durante el pago. Por favor, inténtalo de nuevo",
    "CHOOSE_METHOD": "Elige un método de pago",
    "PAY_CARD": "Pago",
    "SUCCESSFUL": "Payment Was Successful",
    "SUMMARY": "Payment Summary",
    "PROGRESS": "Payment in progress..., please wait",
    "CANCEL": "Please do not refresh this page or click on the back button. If you wish to cancel, please press the cancel button on the payment machine."
  },
  "ORDER": {
    "TITLE": "Mis pedidos",
    "CONFIRMED": "Confirmado",
    "NOTES": "Order Notes",
    "TOTAL": "Total",
    "SUBTOTAL": "Subtotal",
    "ORDER": "Pedido",
    "PAID": "Pagado",
    "PLACED": "Your Order Has Been Placed",
    "ORDERS": "Orders",
    "PREVIOUS": "Previous Orders",
    "REJECTED": "Oops! One or more items or modifiers are currently unavailable and have been removed from your cart."
  },
  "WAITER": {
    "SNACKBAR_TITLE": "Estaremos contigo en breve",
    "SNACKBAR_OK": "Entendido",
    "HELP": "¿Cómo podemos ayudar?",
    "ADDITIONAL_COMMENTS": "Comentarios"
  },
  "MENU": {
    "MENU": "Menú",
    "SEARCH": "Buscar",
    "STAFF": "Llamar a un empleado",
    "ORDERS": "Mis pedidos",
    "BILL": "Mi factura",
    "CONCIERGE": "Conserje",
    "DIRECTORY": "Directorio",
    "SETTINGS": "Idioma",
    "FILTERS": "Filtros Dietéticos",
    "BRANDS": "Brands",
    "SECTIONS": "Sections"
  },
  "SETTINGS": {
    "TITLE": "Ajustes",
    "LANGUAGE": "Idioma",
    "CURRENCY": "Moneda"
  },
  "HOTEL_PREFERENCE": {
    "TITLE": "Selecciona tus preferencias",
    "ROOM": "Cargar a mi habitación",
    "DELIVERY": "Pago contrareembolso",
    "NUMBER_OF_GUESTS": "Número de Invitados"
  },
  "RESCAN": {
    "TITLE": "Código QR",
    "ACTION": "Por favor, vuelve a escanear el código QR para continuar tu sesión",
    "RESCAN": "Rescan",
    "SCANNING": "Scanning..."
  },
  "PICKUP": {
    "TITLE": "Tus Datos"
  },
  "VOUCHER": {
    "ADD": "Añadir un Vale",
    "VOUCHER": "Vale",
    "DISCOUNTED_AMOUNT": "Cantidad Total Después del Descuento",
    "CURRENT_BALANCE": "Saldo",
    "REMAINING_BALANCE": "Saldo Después de la Compra",
    "AMOUNT_DUE": "Importe a Pagar",
    "WARNING": "Voucher can only be applied once per bill"
  }
}
