import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-banner',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.scss'],
    standalone: false
})
export class BannerComponent {
  @Input() public action: string;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public bannerStyle: any;

  @Input() public set banner(banner: string) {
    this.bannerStyle = {
      'background-image': `url('${banner}')`,
    };
  }

  public openBannerAction(): void {
    if (!this.action) {
      return;
    }
    if (this.action.substring(0, 4) === 'tel:' || this.action.substring(0, 7) === 'mailto:') {
      window.location.href = this.action;
    } else {
      window.open(this.action);
    }
  }

}
