{
  "ACTIONS": {
    "CONFIRM": "Подтвердить",
    "SUBMIT": "Отправить",
    "CANCEL": "Отменить",
    "YES": "Да",
    "NO": "Нет",
    "EMPTY": "У Вас нет заказов",
    "CLEAR_ALL": "Очистить все",
    "AWESOME": "потрясающий!",
    "VIEW_RECEIPT": "Квитанция",
    "ADD_CART": "добавить в корзину",
    "SEARCH_PLACEHOLDER": "Поиск еды"
  },
  "COMMON": {
    "CALORIES": "{{calories}} калорий",
    "CHOOSE_MODIFIERS": "Выберите от {{min}} до {{max}}",
    "FEATURE_UNAVAILABLE": "Данная функция в настоящее время недоступна",
    "MISSING_SELECTIONS": "Некоторые параметры отсутствуют. Выберите их, прежде чем продолжить.",
    "ITEM_UNAVAILABLE": "Этот пункт в настоящее время не доступен",
    "REQUIRED": "Необходимый",
    "FEES": "Сборы",
    "MIN_ORDER_ERROR": "Minimum order value of {{currency}} {{amount}} is required to place your order",
    "ADD_MIN_ORDER": "Добавлять {{currency}} {{amount}} чтобы разместить заказ"
  },
  "LANGUAGES": {
    "EN": "English",
    "AR": "عربي",
    "FA": "فارسي",
    "HI": "हिन्दी",
    "RU": "русский",
    "DE": "Deutsch",
    "FR": "Francais",
    "TH": "ไทย",
    "EL": "Ελληνικά",
    "CNR": "Crnogorski",
    "ZH-HANS": "中文",
    "RO": "Română",
    "HU": "Magyar",
    "BG": "български",
    "TR": "Türk",
    "ES": "Español"
  },
  "BILL": {
    "TITLE": "Как бы Вы хотели оплатить?",
    "TOTAL": "Всего:",
    "NAME": "Фамилия",
    "ROOM_NUMBER": "Номер комнаты",
    "SNACKBAR_TITLE": "Ваш счет формируется",
    "SNACKBAR_OK": "Понятно",
    "DISCOUNT": "Скидочное предложение или Карта",
    "PAYMENT_OPTIONS": "Варианты оплаты",
    "PAY_WITH": "Оплатить с помощью",
    "PAY_BILL": "Оплатить счет"
  },
  "CART": {
    "TITLE": "Корзина",
    "TOTAL": "Всего",
    "CURRENT_ORDER": "Текущий заказ",
    "ITEMS": "Позиции",
    "ITEMS_NUMBER": "{{count}} позиций",
    "VIEW": "Просмотреть корзину",
    "CLOSE": "Закрыть корзину",
    "EMPTY": "Пусто",
    "SPECIAL_INSTRUCTION": "Специальные указания ({{charMax}} char)",
    "PLACE_ORDER": "Разместить заказ",
    "OOPS": "Ой",
    "DONE": "Ваш заказ скоро будет готов",
    "GOT_IT": "Понятно",
    "ITEM_LINE": "{{name}}",
    "OOPS_DESC": "Вы пытаетесь изменить позицию с некоторыми модификациями. Пожалуйста, удалите данную позицию из корзины, спасибо!",
    "CONFIRM_CLEAR": "Вы уверены, что хотите очистить корзину?",
    "CLEAR": "Очистить корзину",
    "ITEM_COMMENT": "ДОБАВИТЬ СПЕЦИАЛЬНЫЕ ИНСТРУКЦИИ"
  },
  "PAYMENT": {
    "CONFIRMED": "Ваш заказ был оплачен!",
    "ERROR": "При оплате произошла ошибка, пожалуйста, попробуйте еще раз",
    "CHOOSE_METHOD": "Выберите способ оплаты",
    "PAY_CARD": "Касса",
    "SUCCESSFUL": "Платеж прошел успешно",
    "SUMMARY": "Сводка платежей",
    "PROGRESS": "Платеж в процессе..., пожалуйста, подождите",
    "CANCEL": "Пожалуйста, не обновляйте эту страницу"
  },
  "ORDER": {
    "TITLE": "Мои заказы",
    "CONFIRMED": "Подтвержденные",
    "NOTES": "Примечания к заказу",
    "TOTAL": "Всего",
    "SUBTOTAL": "Промежуточный итог",
    "ORDER": "Заказ",
    "PAID": "Оплаченный",
    "PLACED": "Ваш заказ был размещен",
    "ORDERS": "Заказы",
    "PREVIOUS": "Предыдущие заказы",
    "REJECTED": "Товары в данный момент недоступны"
  },
  "WAITER": {
    "SNACKBAR_TITLE": "Мы скоро придем",
    "SNACKBAR_OK": "Понятно",
    "HELP": "Чем мы можем помочь?",
    "ADDITIONAL_COMMENTS": "Комментарии"
  },
  "MENU": {
    "MENU": "Меню",
    "SEARCH": "Поиск",
    "STAFF": "Вызов персонала",
    "ORDERS": "Мои заказы",
    "BILL": "Мои счета",
    "CONCIERGE": "Консьерж",
    "DIRECTORY": "Каталог",
    "SETTINGS": "Язык",
    "FILTERS": "Фильтры питания",
    "BRANDS": "Бренды",
    "SECTIONS": "Разделы"
  },
  "SETTINGS": {
    "TITLE": "Настройки",
    "LANGUAGE": "Язык",
    "CURRENCY": "Валюта"
  },
  "HOTEL_PREFERENCE": {
    "TITLE": "Пожалуйста, выберите свои предпочтения",
    "ROOM": "В номер",
    "DELIVERY": "Оплата при доставке",
    "NUMBER_OF_GUESTS": "Столовые приборы для "
  },
  "RESCAN": {
    "TITLE": "QR",
    "ACTION": "Пожалуйста, повторно отсканируйте QR, чтобы продолжить сеанс",
    "RESCAN": "Повторное сканирование",
    "SCANNING": "Сканирование..."
  },
  "PICKUP": {
    "TITLE": "Ваши данные"
  },
  "VOUCHER": {
    "ADD": "Добавить ваучер",
    "VOUCHER": "Ваучер",
    "DISCOUNTED_AMOUNT": "Итого после скидки",
    "CURRENT_BALANCE": "Баланс",
    "REMAINING_BALANCE": "Баланс после покупки",
    "AMOUNT_DUE": "Сумма долга",
    "WARNING": "Только один ваучер на счет"
  }
}
