{
  "ACTIONS": {
  "CONFIRM": "पुष्टि करना",
  "SUBMIT": "जमा करना",
  "CANCEL": "रद्द करना",
  "YES": "हाँ",
  "NO": "नहीं",
  "EMPTY": "आपके पास कोई ऑर्डर नहीं है",
  "CLEAR_ALL": "सब कुछ हटाएँ",
  "AWESOME": "बहुत बढ़िया",
  "VIEW_RECEIPT": "रसीद",
  "ADD_CART": "कार्ट में जोड़ें",
  "SEARCH_PLACEHOLDER": "भोजन या रेस्तरां खोजें"
  },
  "COMMON": {
  "CALORIES": "उष",
  "CHOOSE_MODIFIERS": "चुनें {{न्यूनतम}} से {{अधिकतम}} ",
  "FEATURE_UNAVAILABLE": "सुविधा फिलहाल अनुपलब्ध है",
  "MISSING_SELECTIONS": "कुछ विकल्प गायब हैं कृपया आगे बढ़ने से पहले चयन करें",
  "ITEM_UNAVAILABLE": "यह आइटम फ़िलहाल उपलब्ध नहीं है",
  "REQUIRED": "आवश्यक",
  "FEES": "शुल्क",
  "MIN_ORDER_ERROR": "अपना ऑर्डर देने के लिए न्यूनतम ऑर्डर मूल्य {{मुद्रा}} {{राशि}} आवश्यक है",
  "ADD_MIN_ORDER": "अपना ऑर्डर देने के लिए {{मुद्रा}} {{राशि}} जोड़ें"
  },
  "LANGUAGES": {
    "EN": "English",
    "AR": "عربي",
    "FA": "فارسي",
    "HI": "हिन्दी",
    "RU": "русский",
    "DE": "Deutsch",
    "FR": "Francais",
    "TH": "ไทย",
    "EL": "Ελληνικά",
    "CNR": "Crnogorski",
    "ZH-HANS": "中文",
    "RO": "Română",
    "HU": "Magyar",
    "BG": "български",
    "TR": "Türk",
    "ES": "Español"
  },
  "BILL": {
  "TITLE": "आप भुगतान कैसे करना चाहते हैं",
  "TOTAL": "कुल:",
  "NAME": "कुलनाम",
  "ROOM_NUMBER": "कमरा नंबर",
  "SNACKBAR_TITLE": "आपका बिल आने वाला है",
  "SNACKBAR_OK": "मिल गया",
  "DISCOUNT": "डिस्काउंट ऑफर या कार्ड",
  "PAYMENT_OPTIONS": "भुगतान विकल्प",
  "PAY_WITH": "के साथ भुगतान करें",
  "PAY_BILL": "रुपये की सूची"
  },
  "CART": {
"TITLE": "कार्ट",
  "TOTAL": "कुल",
  "CURRENT_ORDER": "अभी का ऑर्डर",
  "ITEMS": "सामान",
  "ITEMS_NUMBER": "{{ संख्‍या}} सामान",
  "VIEW": "कार्ट देखें और ऑर्डर दें",
  "CLOSE": "कार्ट बंद करें",
  "EMPTY": "खाली",
  "SPECIAL_INSTRUCTION": "विशेष निर्देश",
  "PLACE_ORDER": "अपना आर्डर दें",
  "OOPS": "उफ़",
  "DONE": "आपका ऑर्डर जल्द ही आपके पास होगा",
  "GOT_IT": "समझ गया",
  "ITEM_LINE": "{{नाम}}",
  "OOPS_DESC": "क्योंकि जिस आइटम को आप संशोधित करने का प्रयास कर रहे हैं, उसमें कुछ संशोधक जुड़े हुए हैं, कृपया इसे कार्ट से हटा दें, धन्यवाद!",
  "CONFIRM_CLEAR": "क्या आप वाकई कार्ट खाली करना चाहते हैं?",
  "CLEAR": "कार्ट को खाली करें",
  "ITEM_COMMENT": "विशेष निर्देश जोड़ें"
  },
  "PAYMENT": {
  "CONFIRMED": "आपके ऑर्डर का भुगतान कर दिया गया है!",
  "ERROR": "भुगतान के दौरान कोई त्रुटि हुई, कृपया पुनः प्रयास करें",
  "CHOOSE_METHOD": "भुगतान विधि चुनें",
  "PAY_CARD": "चेक आउट",
  "SUCCESSFUL": "भुगतान सफल रहा",
  "SUMMARY": "बिल सारांश",
  "PROGRESS": "भुगतान प्रगति पर है..., कृपया प्रतीक्षा करें",
  "CANCEL": "कृपया इस पेज को रिफ्रेश न करें या बैक बटन पर क्लिक न करें। यदि आप रद्द करना चाहते हैं, तो कृपया भुगतान मशीन पर रद्द करें बटन दबाएँ।"
  },
  "ORDER": {
  "TITLE": "मेरे फ़रमाईसी सामान",
  "CONFIRMED": "पुष्टि करना",
  "NOTES": "ऑर्डर नोट",
  "TOTAL": "कुल",
  "SUBTOTAL": "उप-योग",
  "ORDER": "फ़रमाईसी सामान",
  "PAID": "चुकाया हुआ",
  "PLACED": "आपका ऑर्डर दे दिया गया है",
  "ORDERS": "फ़रमाईसी सामान",
  "PREVIOUS": "पिछले ऑर्डर",
  "REJECTED": "ओह! एक या अधिक आइटम या संशोधक वर्तमान में अनुपलब्ध हैं और उन्हें आपके कार्ट से हटा दिया गया है।"
  },
  "WAITER": {
  "SNACKBAR_TITLE": "हम शीघ्र ही आपके साथ होंगे",
  "SNACKBAR_OK": "समझ गया",
  "HELP": "हम कैसे मदद कर सकते हैं?",
  "ADDITIONAL_COMMENTS": "टिप्पणियाँ"
  },
  "MENU": {
  "MENU": "मेनू",
  "SEARCH": "खोज",
  "STAFF": "स्टाफ को बुलाओ",
  "ORDERS": "मेरे फ़रमाईसी सामान",
  "BILL": "मेरा बिल",
  "CONCIERGE": "कंसीयज",
  "DIRECTORY": "निर्देशिका",
  "SETTINGS": "भाषा",
  "FILTERS": "आहार फ़िल्टर",
  "BRANDS": "ब्रांड्स",
  "SECTIONS": "अनुभाग"
  },
  "SETTINGS": {
  "TITLE": "व्यवस्था",
  "LANGUAGE": "भाषा",
  "CURRENCY": "मुद्रा"
  },
  "HOTEL_PREFERENCE": {
  "TITLE": "कृपया अपनी प्राथमिकताएं चुनें",
  "ROOM": "मेरे कमरे का शुल्क",
  "DELIVERY": "पहुंचने पर भुगतान",
  "NUMBER_OF_GUESTS": "मेहमानों की संख्या"
  },
  "RESCAN": {
  "TITLE": "क्यू आर ",
  "ACTION": "कृपया अपना सत्र जारी रखने के लिए QR को पुनः स्कैन करें",
  "RESCAN": "पुन: स्कैन",
  "SCANNING": "स्कैनिंग…"
  },
  "PICKUP": {
  "TITLE": "आपका विवरण"
  },
  "VOUCHER": {
  "ADD": "वाउचर जोड़ें",
  "VOUCHER": "वाउचर",
  "DISCOUNTED_AMOUNT": "छूट के बाद कुल",
  "CURRENT_BALANCE": "शेष राशि",
  "REMAINING_BALANCE": "खरीद के बाद शेष राशि",
  "AMOUNT_DUE": "देय राशि",
  "WARNING": "वाउचर का उपयोग प्रति बिल केवल एक बार ही किया जा सकता है"
  }
}
