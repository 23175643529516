{
  "ACTIONS": {
    "CONFIRM": "Επιβεβαίωση",
    "SUBMIT": "Υποβολή",
    "CANCEL": "Ακύρωση",
    "YES": "Ναι",
    "NO": "Όχι",
    "EMPTY": "Δεν έχετε παραγγελίες",
    "CLEAR_ALL": "Clear All",
    "AWESOME": "Awesome!",
    "VIEW_RECEIPT": "The Receipt",
    "ADD_CART": "Add to Cart",
    "SEARCH_PLACEHOLDER": "Search for food or restaurants"
  },
  "COMMON": {
    "CALORIES": "{{calories}} Cal",
    "CHOOSE_MODIFIERS": "Choose {{min}} to {{max}}",
    "FEATURE_UNAVAILABLE": "Feature currently unavailable",
    "MISSING_SELECTIONS": "Some options are missing, please select before proceeding",
    "ITEM_UNAVAILABLE": "This item is currently not available",
    "REQUIRED": "Required",
    "FEES": "Fees",
    "MIN_ORDER_ERROR": "Minimum order value of {{currency}} {{amount}} is required to place your order",
    "ADD_MIN_ORDER": "Add {{currency}} {{amount}} to place your order"
  },
  "LANGUAGES": {
    "EN": "English",
    "AR": "عربي",
    "FA": "فارسي",
    "HI": "हिन्दी",
    "RU": "русский",
    "DE": "Deutsch",
    "FR": "Francais",
    "TH": "ไทย",
    "EL": "Ελληνικά",
    "CNR": "Crnogorski",
    "ZH-HANS": "中文",
    "RO": "Română",
    "HU": "Magyar",
    "BG": "български",
    "TR": "Türk",
    "ES": "Español"
  },
  "BILL": {
    "TITLE": "Πώς επιθυμείτε να πληρώσετε;",
    "TOTAL": "Σύνολο:",
    "NAME": "Επώνυμο",
    "ROOM_NUMBER": "Αριθμός Δωματίου",
    "SNACKBAR_TITLE": "Ο λογαριασμός σας είναι έτοιμος",
    "SNACKBAR_OK": "Το κατάλαβα",
    "DISCOUNT": "Προσφορά Έκπτωσης ή Κάρτα",
    "PAYMENT_OPTIONS": "Επιλογές Πληρωμής",
    "PAY_WITH": "Πληρωμή με",
    "PAY_BILL": "Πληρωμή"
  },
  "CART": {
    "TITLE": "Καλάθι",
    "TOTAL": "Σύνολο",
    "CURRENT_ORDER": "Τρέχουσα Παραγγελία",
    "ITEMS": "Αντικείμενα",
    "ITEMS_NUMBER": "{{count}} αντικείμενα",
    "VIEW": "Προβολή καλαθιού",
    "CLOSE": "Κλείσιμο καλαθιού",
    "EMPTY": "Άδειο",
    "SPECIAL_INSTRUCTION": "Ειδική οδηγία ({{charMax}} char)",
    "PLACE_ORDER": "Τοποθετήστε την παραγγελία",
    "OOPS": "Ωχ",
    "DONE": "Η παραγγελία σας θα είναι σε εσάς σύντομα",
    "GOT_IT": "Το κατάλαβα",
    "ITEM_LINE": "{{name}}",
    "OOPS_DESC": "Επειδή το αντικείμενο που προσπαθείτε να τροποποιήσετε έχει συνημμένους ορισμένους τροποποιητές, αφαιρέστε το από το καλάθι, ευχαριστούμε !",
    "CONFIRM_CLEAR": "Are you sure you want to clear the cart?",
    "CLEAR": "Clear Cart",
    "ITEM_COMMENT": "ADD SPECIAL INSTRUCTIONS"
  },
  "PAYMENT": {
    "CONFIRMED": "Your order has been paid !",
    "ERROR": "An error occurred during payment, please try again",
    "CHOOSE_METHOD": "Pick a payment method",
    "PAY_CARD": "Checkout",
    "SUCCESSFUL": "Payment Was Successful",
    "SUMMARY": "Payment Summary",
    "PROGRESS": "Payment in progress..., please wait",
    "CANCEL": "Please do not refresh this page or click on the back button. If you wish to cancel, please press the cancel button on the payment machine."
  },
  "ORDER": {
    "TITLE": "Παραγγελίες",
    "CONFIRMED": "Confirmed",
    "NOTES": "Order Notes",
    "TOTAL": "Σύνολο",
    "SUBTOTAL": "Subtotal",
    "ORDER": "Παραγγελία",
    "PAID": "Paid",
    "PLACED": "Your Order Has Been Placed",
    "ORDERS": "Orders",
    "PREVIOUS": "Previous Orders",
    "REJECTED": "Oops! One or more items or modifiers are currently unavailable and have been removed from your cart."
  },
  "WAITER": {
    "SNACKBAR_TITLE": "Θα είμαστε μαζί σας σύντομα",
    "SNACKBAR_OK": "Το κατάλαβα",
    "HELP": "Πώς μπορούμε να βοηθήσουμε;",
    "ADDITIONAL_COMMENTS": "Comments"
  },
  "MENU": {
    "MENU": "Μενού",
    "SEARCH": "Αναζήτηση",
    "STAFF": "Καλέστε το προσωπικό",
    "ORDERS": "Οι παραγγελίες μου",
    "BILL": "Λογαριασμός",
    "CONCIERGE": "Θυρωρός",
    "DIRECTORY": "Κατάλογος",
    "SETTINGS": "Γλώσσα",
    "FILTERS": "Dietery Filters",
    "BRANDS": "Brands",
    "SECTIONS": "Sections"
  },
  "SETTINGS": {
    "TITLE": "Ρυθμίσεις",
    "LANGUAGE": "Γλώσσα",
    "CURRENCY": "Currency"
  },
  "HOTEL_PREFERENCE": {
    "TITLE": "Παρακαλούμε επιλέξτε τις προτιμήσεις σας",
    "ROOM": "Δωμάτιοpay",
    "DELIVERY": "Πληρωμή κατά την παράδοση",
    "NUMBER_OF_GUESTS": "Προσκεκλημένοι "
  },
  "RESCAN": {
    "TITLE": "QR",
    "ACTION": "Please rescan the QR to continue your session",
    "RESCAN": "Rescan",
    "SCANNING": "Scanning..."
  },
  "PICKUP": {
    "TITLE": "Your Details"
  },
  "VOUCHER": {
    "ADD": "Add a Voucher",
    "VOUCHER": "Voucher",
    "DISCOUNTED_AMOUNT": "Total After Discount",
    "CURRENT_BALANCE": "Balance",
    "REMAINING_BALANCE": "Balance After Purchase",
    "AMOUNT_DUE": "Amount Due",
    "WARNING": "Voucher can only be applied once per bill"
  }
}
