{
  "ACTIONS": {
    "CONFIRM": "Confirmer",
    "SUBMIT": "Envoyer",
    "CANCEL": "Annuler",
    "YES": "Oui",
    "NO": "Non",
    "EMPTY": "Il n'y a rien ici",
    "CLEAR_ALL": "Tout enlever",
    "AWESOME": "Super!",
    "VIEW_RECEIPT": "Voir mon reçu",
    "ADD_CART": "Ajouter au panier",
    "SEARCH_PLACEHOLDER": "Chercher des plats ou restaurants"
  },
  "COMMON": {
    "CALORIES": "{{calories}} Cal",
    "CHOOSE_MODIFIERS": "Choisissez de {{min}} à {{max}}",
    "FEATURE_UNAVAILABLE": "Fonctionnalité non disponible",
    "MISSING_SELECTIONS": "Il manque certaines options, veuillez les sélectionner avant de continuer",
    "ITEM_UNAVAILABLE": "Ce produit n'est plus disponible",
    "REQUIRED": "Requis",
    "FEES": "Frais",
    "MIN_ORDER_ERROR": "Vous devez commander pour {{amount}} {{currency}} afin de placer votre commande",
    "ADD_MIN_ORDER": "Ajoutez {{amount}} {{currency}}  pour pouvoir placer votre commande"
  },
  "LANGUAGES": {
    "EN": "English",
    "AR": "عربي",
    "FA": "فارسي",
    "HI": "हिन्दी",
    "RU": "русский",
    "DE": "Deutsch",
    "FR": "Francais",
    "TH": "ไทย",
    "EL": "Ελληνικά",
    "CNR": "Crnogorski",
    "ZH-HANS": "中文",
    "RO": "Română",
    "HU": "Magyar",
    "BG": "български",
    "TR": "Türk",
    "ES": "Español"
  },
  "BILL": {
    "TITLE": "Comment souhaitez vous payer?",
    "TOTAL": "Total:",
    "NAME": "Nom",
    "ROOM_NUMBER": "Numéro de chambre",
    "SNACKBAR_TITLE": "Nous vous amenons votre addition",
    "SNACKBAR_OK": "Ok",
    "DISCOUNT": "Avez vous une carte de discount ?",
    "PAYMENT_OPTIONS": "Options de paiement",
    "PAY_WITH": "Payer avec",
    "PAY_BILL": "Payer l'addition"
  },
  "CART": {
    "TITLE": "Panier",
    "TOTAL": "Total",
    "CURRENT_ORDER": "Ma commande",
    "ITEMS": "plats",
    "ITEMS_NUMBER": "{{count}} plats",
    "VIEW": "Voir mon panier & Placer ma commande",
    "CLOSE": "Fermer le panier",
    "EMPTY": "Votre panier est vide",
    "SPECIAL_INSTRUCTION": "Instructions ({{charMax}} car)",
    "PLACE_ORDER": "Placer ma commande",
    "OOPS": "Oups",
    "DONE": "Vous recevrez bientôt votre commande",
    "GOT_IT": "Compris",
    "ITEM_LINE": "{{name}}",
    "OOPS_DESC": "Veuillez retirer ce plat depuis votre panier, merci!",
    "CONFIRM_CLEAR": "Souhaitez vous vider votre panier ?",
    "CLEAR": "Vider le panier",
    "ITEM_COMMENT": "Ajouter un commentaire"
  },
  "PAYMENT": {
    "CONFIRMED": "Votre commande a été payé !",
    "ERROR": "Une erreur est survenue, veuillez essayer a nouveau",
    "CHOOSE_METHOD": "Veuillez choisir une méthode de paiement",
    "PAY_CARD": "Payer",
    "SUCCESSFUL": "Le paiement a échoué",
    "SUMMARY": "Addition",
    "PROGRESS": "Paiement en cours... veuillez patienter",
    "CANCEL": "Merci de ne pas rafraichir la page ou cliquer sur le bouton retour. Si vous souhaitez annuler, veuillez presser le bouton cancel sur la machine."
  },
  "ORDER": {
    "TITLE": "Mes commandes",
    "CONFIRMED": "Confirmé",
    "NOTES": "Ajouter un commentaire",
    "TOTAL": "Total",
    "SUBTOTAL": "Sous-total",
    "ORDER": "Commander",
    "PAID": "Paid",
    "PLACED": "Votre commande a été placé",
    "ORDERS": "Commandes",
    "PREVIOUS": "Commandes précédente",
    "REJECTED": "Oops ! Un ou plusieurs plats ne sont plus disponible et ont été retirés de votre panier."
  },
  "WAITER": {
    "SNACKBAR_TITLE": "Vous recevrez bientôt votre commande",
    "SNACKBAR_OK": "Ok",
    "HELP": "Comment pouvons nous aider ?",
    "ADDITIONAL_COMMENTS": "Commentaires"
  },
  "MENU": {
    "MENU": "Menu",
    "SEARCH": "Rechercher",
    "STAFF": "Serveur",
    "ORDERS": "Commandes",
    "BILL": "Addition",
    "CONCIERGE": "Concierge",
    "DIRECTORY": "Annuaire",
    "SETTINGS": "Langue",
    "FILTERS": "Filtres alimentaire",
    "BRANDS": "Marques",
    "SECTIONS": "Sections"
  },
  "HOTEL_PREFERENCE": {
    "TITLE": "Veuilez selectionner votre préférence",
    "ROOM": "Ajouter a ma note",
    "DELIVERY": "Payer à la livraison",
    "NUMBER_OF_GUESTS": "Couverts pour "
  },
  "RESCAN": {
    "TITLE": "QR",
    "ACTION": "Veuillez scanner a nouveau le QR pour continuer votre session",
    "RESCAN": "Scanner a nouveau",
    "SCANNING": "Scan en cours..."
  },
  "PICKUP": {
    "TITLE": "Vos renseignements"
  },
  "SETTINGS": {
    "TITLE": "Paramètres",
    "LANGUAGE": "Langage",
    "CURRENCY": "Devise"
  },
  "VOUCHER": {
    "ADD": "Ajouter un coupon",
    "VOUCHER": "Coupon",
    "DISCOUNTED_AMOUNT": "Total Apres Discount",
    "CURRENT_BALANCE": "Solde",
    "REMAINING_BALANCE": "Solde après achat",
    "AMOUNT_DUE": "Solde du",
    "WARNING": "Voucher can only be applied once per bill"
  }
}
