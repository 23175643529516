{
  "ACTIONS": {
    "CONFIRM": "Bestätigen",
    "SUBMIT": "Einreichen",
    "CANCEL": "Abbrechen",
    "YES": "Ja",
    "NO": "Nein",
    "EMPTY": "Sie haben keine Bestellungen",
    "CLEAR_ALL": "Alle löschen",
    "AWESOME": "Großartig!",
    "VIEW_RECEIPT": "Die Quittung",
    "ADD_CART": "Zum Warenkorb hinzufügen",
    "SEARCH_PLACEHOLDER": "Suche nach Lebensmitteln oder Restaurants"
  },
  "COMMON": {
    "CALORIES": "{{calories}} Cal",
    "CHOOSE_MODIFIERS": "Wählen Sie {{min}} zu {{max}}",
    "FEATURE_UNAVAILABLE": "Funktion derzeit nicht verfügbar",
    "MISSING_SELECTIONS": "Es fehlen einige Optionen, bitte wählen Sie diese aus, bevor Sie fortfahren",
    "ITEM_UNAVAILABLE": "Dieser Artikel ist derzeit nicht verfügbar",
    "REQUIRED": "Erforderlich",
    "FEES": "Gebühren",
    "MIN_ORDER_ERROR": "Mindestbestellwert von {{currency}} {{amount}} ist erforderlich, um Ihre Bestellung aufzugeben",
    "ADD_MIN_ORDER": " Fügen Sie {{currency}} {{amount}}, um Ihre Bestellung aufzugeben"
  },
  "LANGUAGES": {
    "EN": "English",
    "AR": "عربي",
    "FA": "فارسي",
    "HI": "हिन्दी",
    "RU": "русский",
    "DE": "Deutsch",
    "FR": "Francais",
    "TH": "ไทย",
    "EL": "Ελληνικά",
    "CNR": "Crnogorski",
    "ZH-HANS": "中文",
    "RO": "Română",
    "HU": "Magyar",
    "BG": "български",
    "TR": "Türk",
    "ES": "Español"
  },
  "BILL": {
    "TITLE": "Wie möchten Sie bezahlen?",
    "TOTAL": "Insgesamt:",
    "NAME": "Nachname",
    "ROOM_NUMBER": "Zimmernummer",
    "SNACKBAR_TITLE": "Ihre Quittung ist auf dem Weg!",
    "SNACKBAR_OK": "Got it",
    "DISCOUNT": "Rabattangebot oder Karte",
    "PAYMENT_OPTIONS": "Zahlungsoptionen",
    "PAY_WITH": "Zahlen mit",
    "PAY_BILL": "Rechnung bezahlen"
  },
  "CART": {
    "TITLE": "Warenkorb",
    "TOTAL": "Insgesamt",
    "CURRENT_ORDER": "Aktuelle Bestellung",
    "ITEMS": "Items",
    "ITEMS_NUMBER": "{{count}} Artikel",
    "VIEW": "Warenkorb ansehen & Bestellung aufgeben",
    "CLOSE": "Warenkorb schließen",
    "EMPTY": "Leer",
    "SPECIAL_INSTRUCTION": "Besonderer Auftrag({{charMax}} char)",
    "PLACE_ORDER": "Bestellung aufgeben",
    "OOPS": "Ups",
    "DONE": "Ihre Bestellung wird bald bei Ihnen sein",
    "GOT_IT": "Verstanden",
    "ITEM_LINE": "{{name}}",
    "OOPS_DESC": "Da der Artikel, den Sie zu ändern versuchen, einige Modifikatoren enthält, entfernen Sie ihn bitte aus dem Warenkorb, danke!",
    "CONFIRM_CLEAR": "Sind Sie sicher, dass Sie den Warenkorb löschen wollen?",
    "CLEAR": "Warenkorb löschen",
    "ITEM_COMMENT": "BESONDERE ANWEISUNGEN HINZUFÜGEN"
  },
  "PAYMENT": {
    "CONFIRMED": "Ihre Bestellung wurde bezahlt!",
    "ERROR": "Bei der Zahlung ist ein Fehler aufgetreten, bitte versuchen Sie es erneut",
    "CHOOSE_METHOD": "Wählen Sie eine Zahlungsmethode",
    "PAY_CARD": "Kasse",
    "SUCCESSFUL": "Die Zahlung war erfolgreich",
    "SUMMARY": "Zusammenfassung der Zahlungen",
    "PROGRESS": "Zahlung in Bearbeitung..., bitte warten",
    "CANCEL": "Bitte aktualisieren Sie diese Seite nicht und klicken Sie nicht auf die Schaltfläche Zurück. Wenn Sie den Vorgang abbrechen möchten, drücken Sie bitte die Abbruchtaste am Zahlungsautomaten."
  },
  "ORDER": {
    "TITLE": "Meine Bestellungen",
    "CONFIRMED": "Bestätigt",
    "NOTES": "Anmerkungen zur Bestellung",
    "TOTAL": "Insgesamt",
    "SUBTOTAL": "Subtotal",
    "ORDER": "Bestellung",
    "PAID": "Bezahlt",
    "PLACED": "Ihre Bestellung wurde aufgegeben",
    "ORDERS": "Bestellungen",
    "PREVIOUS": "Bisherige Bestellungen",
    "REJECTED": "Huch! Ein oder mehrere Artikel oder Modifikatoren sind derzeit nicht verfügbar und wurden aus Ihrem Warenkorb entfernt."
  },
  "WAITER": {
    "SNACKBAR_TITLE": "Wir sind in Kürze für Sie da",
    "SNACKBAR_OK": "Verstanden",
    "HELP": "Wie können wir helfen?",
    "ADDITIONAL_COMMENTS": "Anmerkungen"
  },
  "MENU": {
    "MENU": "Menu",
    "SEARCH": "Suche",
    "STAFF": "Personal anrufen",
    "ORDERS": "Meine Bestellungen",
    "BILL": "Meine Rechnung",
    "CONCIERGE": "Hausmeister",
    "DIRECTORY": "Verzeichnis",
    "SETTINGS": "Sprache",
    "FILTERS": "Diät Filter",
    "BRANDS": "Marken",
    "SECTIONS": "Bereiche"
  },
  "SETTINGS": {
    "TITLE": "Einstellungen",
    "LANGUAGE": "Sprache",
    "CURRENCY": "Währung"
  },
  "HOTEL_PREFERENCE": {
    "TITLE": "Bitte wählen Sie Ihre Vorlieben",
    "ROOM": "Anrechnung auf mein Zimmer",
    "DELIVERY": "Zahlung bei Lieferung",
    "NUMBER_OF_GUESTS": "Besteck für "
  },
  "RESCAN": {
    "TITLE": "QR",
    "ACTION": "Bitte scannen Sie den QR erneut, um Ihre Sitzung fortzusetzen.",
    "RESCAN": "Neu scannen",
    "SCANNING": "Scannen..."
  },
  "PICKUP": {
    "TITLE": "Deine Details"
  },
  "VOUCHER": {
    "ADD": "Einen Gutschein hinzufügen",
    "VOUCHER": "Gutschein",
    "DISCOUNTED_AMOUNT": "Gesamtbetrag nach Rabatt",
    "CURRENT_BALANCE": "Guthaben",
    "REMAINING_BALANCE": "Guthaben nach Kauf",
    "AMOUNT_DUE": "Fälliger Betrag",
    "WARNING": "Der Gutschein kann nur einmal pro Rechnung verwendet werden."
  }
}
