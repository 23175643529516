{
  "ACTIONS": {
    "CONFIRM": "Confirm",
    "SUBMIT": "Submit",
    "CANCEL": "Cancel",
    "YES": "Yes",
    "NO": "No",
    "EMPTY": "You have no orders",
    "CLEAR_ALL": "Clear All",
    "AWESOME": "Awesome!",
    "VIEW_RECEIPT": "The Receipt",
    "ADD_CART": "Add to Cart",
    "SEARCH_PLACEHOLDER": "Search for food or restaurants"
  },
  "COMMON": {
    "CALORIES": "{{calories}} Cal",
    "CHOOSE_MODIFIERS": "Choose {{min}} to {{max}}",
    "FEATURE_UNAVAILABLE": "Feature currently unavailable",
    "MISSING_SELECTIONS": "Some options are missing, please select before proceeding",
    "ITEM_UNAVAILABLE": "This item is currently not available",
    "REQUIRED": "Required",
    "FEES": "Fees",
    "MIN_ORDER_ERROR": "Minimum order value of {{currency}} {{amount}} is required to place your order",
    "ADD_MIN_ORDER": "Add {{currency}} {{amount}} to place your order"
  },
  "LANGUAGES": {
    "EN": "English",
    "AR": "عربي",
    "FA": "فارسي",
    "HI": "हिन्दी",
    "RU": "русский",
    "DE": "Deutsch",
    "FR": "Francais",
    "TH": "ไทย",
    "EL": "Ελληνικά",
    "CNR": "Crnogorski",
    "ZH-HANS": "中文",
    "RO": "Română",
    "HU": "Magyar",
    "BG": "български",
    "TR": "Türk",
    "ES": "Español"
  },
  "BILL": {
    "TITLE": "How do you wish to pay?",
    "TOTAL": "Total:",
    "NAME": "Last Name",
    "ROOM_NUMBER": "Room Number",
    "SNACKBAR_TITLE": "Your bill is on the way",
    "SNACKBAR_OK": "Got it",
    "DISCOUNT": "Discount Offer or Card",
    "PAYMENT_OPTIONS": "Payment Options",
    "PAY_WITH": "Pay with",
    "PAY_BILL": "Pay bill"
  },
  "CART": {
    "TITLE": "Cart",
    "TOTAL": "Total",
    "CURRENT_ORDER": "Current Order",
    "ITEMS": "Items",
    "ITEMS_NUMBER": "{{count}} items",
    "VIEW": "View cart & Place order",
    "CLOSE": "Close cart",
    "EMPTY": "Empty",
    "SPECIAL_INSTRUCTION": "Special instruction ({{charMax}} char)",
    "PLACE_ORDER": "Place order",
    "OOPS": "Oops",
    "DONE": "Your order will be with you soon",
    "GOT_IT": "Got it",
    "ITEM_LINE": "{{name}}",
    "OOPS_DESC": "Because the item you are trying to modify has some modifiers attached to it, please remove it from the cart, thank you !",
    "CONFIRM_CLEAR": "Are you sure you want to clear the cart?",
    "CLEAR": "Clear Cart",
    "ITEM_COMMENT": "ADD SPECIAL INSTRUCTIONS"
  },
  "PAYMENT": {
    "CONFIRMED": "Your order has been paid !",
    "ERROR": "An error occurred during payment, please try again",
    "CHOOSE_METHOD": "Pick a payment method",
    "PAY_CARD": "Checkout",
    "SUCCESSFUL": "Payment Was Successful",
    "SUMMARY": "Payment Summary",
    "PROGRESS": "Payment in progress..., please wait",
    "CANCEL": "Please do not refresh this page or click on the back button. If you wish to cancel, please press the cancel button on the payment machine."
  },
  "ORDER": {
    "TITLE": "My orders",
    "CONFIRMED": "Confirmed",
    "NOTES": "Order Notes",
    "TOTAL": "Total",
    "ORDER": "Order",
    "SUBTOTAL": "Subtotal",
    "PAID": "Paid",
    "PLACED": "Your Order Has Been Placed",
    "ORDERS": "Orders",
    "PREVIOUS": "Previous Orders",
    "REJECTED": "Oops! One or more items or modifiers are currently unavailable and have been removed from your cart."
  },
  "WAITER": {
    "SNACKBAR_TITLE": "We'll be with you shortly",
    "SNACKBAR_OK": "Got it",
    "HELP": "How can we help?",
    "ADDITIONAL_COMMENTS": "Comments"
  },
  "MENU": {
    "MENU": "Menu",
    "SEARCH": "Search",
    "STAFF": "Call staff",
    "ORDERS": "My orders",
    "BILL": "My bill",
    "CONCIERGE": "Concierge",
    "DIRECTORY": "Directory",
    "SETTINGS": "Language",
    "FILTERS": "Dietery Filters",
    "BRANDS": "Brands",
    "SECTIONS": "Sections"
  },
  "SETTINGS": {
    "TITLE": "Settings",
    "LANGUAGE": "Language",
    "CURRENCY": "Currency"
  },
  "HOTEL_PREFERENCE": {
    "TITLE": "Please select your preferences",
    "ROOM": "Charge to my room",
    "DELIVERY": "Pay on delivery",
    "NUMBER_OF_GUESTS": "Cutlery for "
  },
  "RESCAN": {
    "TITLE": "QR",
    "ACTION": "Please rescan the QR to continue your session",
    "RESCAN": "Rescan",
    "SCANNING": "Scanning..."
  },
  "PICKUP": {
    "TITLE": "Your Details"
  },
  "VOUCHER": {
    "ADD": "Add a Voucher",
    "VOUCHER": "Voucher",
    "DISCOUNTED_AMOUNT": "Total After Discount",
    "CURRENT_BALANCE": "Balance",
    "REMAINING_BALANCE": "Balance After Purchase",
    "AMOUNT_DUE": "Amount Due",
    "WARNING": "Voucher can only be applied once per bill"
  }
}
