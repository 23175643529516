import { Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-font-cairo',
    templateUrl: './cairo.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./cairo.component.scss'],
    standalone: false
})
export class FontCairoComponent {

  constructor() {
    document.body.style.setProperty('font-family', 'Cairo, sans-serif');
  }

}
