{
  "ACTIONS": {
    "CONFIRM": "ยืนยัน",
    "SUBMIT": "ส่ง",
    "CANCEL": "ยกเลิก",
    "YES": "ใช่",
    "NO": "ไม่ใช่",
    "EMPTY": "คุณไม่มีรายการสั่งซื้อ",
    "CLEAR_ALL": "Clear All",
    "AWESOME": "Awesome!",
    "VIEW_RECEIPT": "The Receipt",
    "ADD_CART": "Add to Cart",
    "SEARCH_PLACEHOLDER": "Search for food or restaurants"
  },
  "COMMON": {
    "CALORIES": "{{calories}} Cal",
    "CHOOSE_MODIFIERS": "Choose {{min}} to {{max}}",
    "FEATURE_UNAVAILABLE": "Feature currently unavailable",
    "MISSING_SELECTIONS": "Some options are missing, please select before proceeding",
    "ITEM_UNAVAILABLE": "This item is currently not available",
    "REQUIRED": "Required",
    "FEES": "Fees",
    "MIN_ORDER_ERROR": "Minimum order value of {{currency}} {{amount}} is required to place your order",
    "ADD_MIN_ORDER": "Add {{currency}} {{amount}} to place your order"
  },
  "LANGUAGES": {
    "EN": "English",
    "AR": "عربي",
    "FA": "فارسي",
    "HI": "हिन्दी",
    "RU": "русский",
    "DE": "Deutsch",
    "FR": "Francais",
    "TH": "ไทย",
    "EL": "Ελληνικά",
    "CNR": "Crnogorski",
    "ZH-HANS": "中文",
    "RO": "Română",
    "HU": "Magyar",
    "BG": "български",
    "TR": "Türk",
    "ES": "Español"
  },
  "BILL": {
    "TITLE": "คุณต้องการชำระเงินด้วยวิธีใด",
    "TOTAL": "ยอดรวม",
    "NAME": "Last Name",
    "ROOM_NUMBER": "Room Number",
    "SNACKBAR_TITLE": "บิลของคุณอยู่ในระหว่างการจัดส่ง",
    "SNACKBAR_OK": "เข้าใจแล้ว",
    "DISCOUNT": "Discount Offer or Card",
    "PAYMENT_OPTIONS": "วิธีการชำระเงิน",
    "PAY_WITH": "ชำระเงินด้วย",
    "PAY_BILL": "ชำระบิล"
  },
  "CART": {
    "TITLE": "รถเข็น",
    "TOTAL": "ยอดรวม",
    "CURRENT_ORDER": "รายการสั่งซื้อปัจจุบัน",
    "ITEMS": "รายการ",
    "ITEMS_NUMBER": "{{count}} รายการ",
    "VIEW": "ดูรายการสั่งซื้อในรถเข็น & สั่งซื้อ",
    "CLOSE": "ปิดรถเข็น",
    "EMPTY": "รถเข็นเปล่า",
    "SPECIAL_INSTRUCTION": "คำสั่งพิเศษ ({{charMax}} char)",
    "PLACE_ORDER": "สั่งซื้อ",
    "OOPS": "ขออภัยค่ะ/ครับ",
    "DONE": "คุณจะได้รับรายการที่สั่งซื้อในไม่ช้า",
    "GOT_IT": "เข้าใจแล้ว",
    "ITEM_LINE": "{{name}}",
    "OOPS_DESC": "เนื่องจากรายการที่คุณต้องการแก้ไขมีตัวแก้ไขอื่นอยู่ด้วย ดังนั้นขอความกรุณาลบรายการนั้นออกจากรถเข็นของคุณด้วย ขอบคุณค่ะ/ครับ",
    "CONFIRM_CLEAR": "Are you sure you want to clear the cart?",
    "CLEAR": "Clear Cart",
    "ITEM_COMMENT": "ADD SPECIAL INSTRUCTIONS"
  },
  "PAYMENT": {
    "CONFIRMED": "Your order has been paid !",
    "ERROR": "An error occurred during payment, please try again",
    "CHOOSE_METHOD": "Pick a payment method",
    "PAY_CARD": "Checkout",
    "SUCCESSFUL": "Payment Was Successful",
    "SUMMARY": "Payment Summary",
    "PROGRESS": "Payment in progress..., please wait",
    "CANCEL": "Please do not refresh this page or click on the back button. If you wish to cancel, please press the cancel button on the payment machine."
  },
  "ORDER": {
    "TITLE": "รายการสั่งซื้อของฉัน",
    "CONFIRMED": "Confirmed",
    "NOTES": "Order Notes",
    "TOTAL": "ยอดรวม",
    "SUBTOTAL": "Subtotal",
    "ORDER": "รายการสั่งซื้อ",
    "PAID": "Paid",
    "PLACED": "Your Order Has Been Placed",
    "ORDERS": "Orders",
    "PREVIOUS": "Previous Orders",
    "REJECTED": "Oops! One or more items or modifiers are currently unavailable and have been removed from your cart."
  },
  "WAITER": {
    "SNACKBAR_TITLE": "เราจะติดต่อกลับหาคุณโดยเร็วที่สุด",
    "SNACKBAR_OK": "เข้าใจแล้ว",
    "HELP": "ให้เราช่วยเหลืออะไรคุณได้บ้าง",
    "ADDITIONAL_COMMENTS": "Comments"
  },
  "MENU": {
    "MENU": "เมนู",
    "SEARCH": "ค้นหา",
    "STAFF": "พนักงานบริการ",
    "ORDERS": "ออร์เดอร์ของฉัน",
    "BILL": "ชำระบิล",
    "CONCIERGE": "ติดต่อเจ้าหน้าที่",
    "DIRECTORY": "สมุดรายชื่อ",
    "SETTINGS": "ภาษา",
    "FILTERS": "Dietery Filters",
    "BRANDS": "Brands",
    "SECTIONS": "Sections"
  },
  "SETTINGS": {
    "TITLE": "การตั้งค่า",
    "LANGUAGE": "ภาษา",
    "CURRENCY": "Currency"
  },
  "HOTEL_PREFERENCE": {
    "TITLE": "กรุณาเลือกสิ่งที่คุณต้องการเพิ่มเติมเป็นพิเศษ",
    "ROOM": "คิดรวมกับค่าห้อง",
    "DELIVERY": "ชำระเงินเมื่อได้รับสินค้า",
    "NUMBER_OF_GUESTS": "Cutlery for "
  },
  "RESCAN": {
    "TITLE": "QR",
    "ACTION": "Please rescan the QR to continue your session",
    "RESCAN": "Rescan",
    "SCANNING": "Scanning..."
  },
  "PICKUP": {
    "TITLE": "Your Details"
  },
  "VOUCHER": {
    "ADD": "Add a Voucher",
    "VOUCHER": "Voucher",
    "DISCOUNTED_AMOUNT": "Total After Discount",
    "CURRENT_BALANCE": "Balance",
    "REMAINING_BALANCE": "Balance After Purchase",
    "AMOUNT_DUE": "Amount Due",
    "WARNING": "Voucher can only be applied once per bill"
  }
}
