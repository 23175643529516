{
  "ACTIONS": {
    "CONFIRM": "Megerősítés",
    "SUBMIT": "Beküldés",
    "CANCEL": "Visszavonás",
    "YES": "Igen",
    "NO": "Nem",
    "EMPTY": "Nincs megrendelése",
    "CLEAR_ALL": "Clear All",
    "AWESOME": "Awesome!",
    "VIEW_RECEIPT": "The Receipt",
    "ADD_CART": "Add to Cart",
    "SEARCH_PLACEHOLDER": "Search for food or restaurants"
  },
  "COMMON": {
    "CALORIES": "{{calories}} Cal",
    "CHOOSE_MODIFIERS": "Choose {{min}} to {{max}}",
    "FEATURE_UNAVAILABLE": "Feature currently unavailable",
    "MISSING_SELECTIONS": "Some options are missing, please select before proceeding",
    "ITEM_UNAVAILABLE": "This item is currently not available",
    "REQUIRED": "Required",
    "FEES": "Fees",
    "MIN_ORDER_ERROR": "Minimum order value of {{currency}} {{amount}} is required to place your order",
    "ADD_MIN_ORDER": "Add {{currency}} {{amount}} to place your order"
  },
  "LANGUAGES": {
    "EN": "English",
    "AR": "عربي",
    "FA": "فارسي",
    "HI": "हिन्दी",
    "RU": "русский",
    "DE": "Deutsch",
    "FR": "Francais",
    "TH": "ไทย",
    "EL": "Ελληνικά",
    "CNR": "Crnogorski",
    "ZH-HANS": "中文",
    "RO": "Română",
    "HU": "Magyar",
    "BG": "български",
    "TR": "Türk",
    "ES": "Español"
  },
  "BILL": {
    "TITLE": "Hogyan szeretne fizetni?",
    "TOTAL": "Összes:",
    "NAME": "Vezetéknév",
    "ROOM_NUMBER": "Szoba száma",
    "SNACKBAR_TITLE": "A számlája hamarosan érkezik",
    "SNACKBAR_OK": "Megvan",
    "DISCOUNT": "Kedvezményes ajánlat vagy kártya",
    "PAYMENT_OPTIONS": "Fizetési lehetőségek",
    "PAY_WITH": "Fizessen",
    "PAY_BILL": "Fizesse ki a számlát"
  },
  "CART": {
    "TITLE": "Kosár",
    "TOTAL": "Összesen",
    "CURRENT_ORDER": "Aktuális rendelés",
    "ITEMS": "Árucikkek",
    "ITEMS_NUMBER": "{{count}} az árucikkeket",
    "VIEW": "Kosár megtekintése & Rendelje meg",
    "CLOSE": "Kosár bezárása",
    "EMPTY": "Üres",
    "SPECIAL_INSTRUCTION": "Különleges utasítás ({{charMax}} char)",
    "PLACE_ORDER": "Rendelje meg",
    "OOPS": "Hoppá",
    "DONE": "Hamarosan megérkezik rendelése",
    "GOT_IT": "Rendben",
    "ITEM_LINE": "{{name}}",
    "OOPS_DESC": "Mivel a módosítani kívánt elemhez csatolva van néhány módosító, kérjük, vegye ki a kosárból, köszönöm!",
    "CONFIRM_CLEAR": "Are you sure you want to clear the cart?",
    "CLEAR": "Clear Cart",
    "ITEM_COMMENT": "ADD SPECIAL INSTRUCTIONS"
  },
  "PAYMENT": {
    "CONFIRMED": "Your order has been paid !",
    "ERROR": "An error occurred during payment, please try again",
    "CHOOSE_METHOD": "Pick a payment method",
    "PAY_CARD": "Checkout",
    "SUCCESSFUL": "Payment Was Successful",
    "SUMMARY": "Payment Summary",
    "PROGRESS": "Payment in progress..., please wait",
    "CANCEL": "Please do not refresh this page or click on the back button. If you wish to cancel, please press the cancel button on the payment machine."
  },
  "ORDER": {
    "TITLE": "Rendeléseim",
    "CONFIRMED": "Confirmed",
    "NOTES": "Order Notes",
    "TOTAL": "Összes",
    "SUBTOTAL": "Subtotal",
    "ORDER": "Rendelés",
    "PAID": "Paid",
    "PLACED": "Your Order Has Been Placed",
    "ORDERS": "Orders",
    "PREVIOUS": "Previous Orders",
    "REJECTED": "Oops! One or more items or modifiers are currently unavailable and have been removed from your cart."
  },
  "WAITER": {
    "SNACKBAR_TITLE": "Hamarosan Önnél vagyunk",
    "SNACKBAR_OK": "Rendben",
    "HELP": "Hogyan tudunk segíteni?",
    "ADDITIONAL_COMMENTS": "Comments"
  },
  "MENU": {
    "MENU": "Menü",
    "SEARCH": "Keresés",
    "STAFF": "Személyzet",
    "ORDERS": "Megredeléseim",
    "BILL": "A számlám",
    "CONCIERGE": "Portás",
    "DIRECTORY": "Könyvtár",
    "SETTINGS": "Nyelv",
    "FILTERS": "Dietery Filters",
    "BRANDS": "Brands",
    "SECTIONS": "Sections"
  },
  "SETTINGS": {
    "TITLE": "Beállítások",
    "LANGUAGE": "Nyelv",
    "CURRENCY": "Currency"
  },
  "HOTEL_PREFERENCE": {
    "TITLE": "Kérjük válassza ki preferenciáit",
    "ROOM": "Tegye a szobám számlájára",
    "DELIVERY": "Fizessen kiszállításkor",
    "NUMBER_OF_GUESTS": "Evőeszközök"
  },
  "RESCAN": {
    "TITLE": "QR",
    "ACTION": "Please rescan the QR to continue your session",
    "RESCAN": "Rescan",
    "SCANNING": "Scanning..."
  },
  "PICKUP": {
    "TITLE": "Your Details"
  },
  "VOUCHER": {
    "ADD": "Add a Voucher",
    "VOUCHER": "Voucher",
    "DISCOUNTED_AMOUNT": "Total After Discount",
    "CURRENT_BALANCE": "Balance",
    "REMAINING_BALANCE": "Balance After Purchase",
    "AMOUNT_DUE": "Amount Due",
    "WARNING": "Voucher can only be applied once per bill"
  }
}
