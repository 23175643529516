{
  "ACTIONS": {
    "CONFIRM": "确认",
    "SUBMIT": "提交",
    "CANCEL": "取消",
    "YES": "是",
    "NO": "否",
    "EMPTY": "未找到",
    "CLEAR_ALL": "Clear All",
    "AWESOME": "Awesome!",
    "VIEW_RECEIPT": "The Receipt",
    "ADD_CART": "Add to Cart",
    "SEARCH_PLACEHOLDER": "Search for food or restaurants"
  },
  "COMMON": {
    "CALORIES": "{{calories}} Cal",
    "CHOOSE_MODIFIERS": "Choose {{min}} to {{max}}",
    "FEATURE_UNAVAILABLE": "Feature currently unavailable",
    "MISSING_SELECTIONS": "Some options are missing, please select before proceeding",
    "ITEM_UNAVAILABLE": "This item is currently not available",
    "REQUIRED": "Required",
    "FEES": "Fees",
    "MIN_ORDER_ERROR": "Minimum order value of {{currency}} {{amount}} is required to place your order",
    "ADD_MIN_ORDER": "Add {{currency}} {{amount}} to place your order"
  },
  "LANGUAGES": {
    "EN": "English",
    "AR": "عربي",
    "FA": "فارسي",
    "HI": "हिन्दी",
    "RU": "русский",
    "DE": "Deutsch",
    "FR": "Francais",
    "TH": "ไทย",
    "EL": "Ελληνικά",
    "CNR": "Crnogorski",
    "ZH-HANS": "中文",
    "RO": "Română",
    "HU": "Magyar",
    "BG": "български",
    "TR": "Türk",
    "ES": "Español"
  },
  "BILL": {
    "TITLE": "付款方式",
    "TOTAL": "总计:",
    "NAME": "Last Name",
    "ROOM_NUMBER": "Room Number",
    "SNACKBAR_TITLE": "Your bill is on the way",
    "SNACKBAR_OK": "Got it",
    "DISCOUNT": "打折码?",
    "PAYMENT_OPTIONS": "付款方式",
    "PAY_WITH": "付款方式",
    "PAY_BILL": "付款"
  },
  "CART": {
    "TITLE": "已点餐详情",
    "TOTAL": "总计",
    "CURRENT_ORDER": "数量",
    "ITEMS": "菜单",
    "ITEMS_NUMBER": "{{count}} 菜单",
    "VIEW": "查看当前已点餐 & 确认",
    "CLOSE": "关闭",
    "EMPTY": "没有已选餐",
    "SPECIAL_INSTRUCTION": "留言 ({{charMax}} char)",
    "PLACE_ORDER": "确认",
    "OOPS": "系统出现问题",
    "DONE": "您的点餐已确认",
    "GOT_IT": "关闭",
    "ITEM_LINE": "{{name}}",
    "OOPS_DESC": "请从已点餐列表中删除，谢谢",
    "CONFIRM_CLEAR": "Are you sure you want to clear the cart?",
    "CLEAR": "Clear Cart",
    "ITEM_COMMENT": "ADD SPECIAL INSTRUCTIONS"
  },
  "PAYMENT": {
    "CONFIRMED": "Your order has been paid !",
    "ERROR": "An error occurred during payment, please try again",
    "CHOOSE_METHOD": "Pick a payment method",
    "PAY_CARD": "Checkout",
    "SUCCESSFUL": "Payment Was Successful",
    "SUMMARY": "Payment Summary",
    "PROGRESS": "Payment in progress..., please wait",
    "CANCEL": "Please do not refresh this page or click on the back button. If you wish to cancel, please press the cancel button on the payment machine."
  },
  "ORDER": {
    "TITLE": "我的点餐",
    "CONFIRMED": "Confirmed",
    "NOTES": "Order Notes",
    "TOTAL": "总计",
    "SUBTOTAL": "Subtotal",
    "ORDER": "点餐",
    "PAID": "Paid",
    "PLACED": "Your Order Has Been Placed",
    "ORDERS": "Orders",
    "PREVIOUS": "Previous Orders",
    "REJECTED": "Oops! One or more items or modifiers are currently unavailable and have been removed from your cart."
  },
  "WAITER": {
    "SNACKBAR_TITLE": "We'll be with you shortly",
    "SNACKBAR_OK": "Got it",
    "HELP": "需求?",
    "ADDITIONAL_COMMENTS": "Comments"
  },
  "MENU": {
    "MENU": "菜单",
    "SEARCH": "搜索",
    "STAFF": "呼叫服务员",
    "ORDERS": "我的点餐",
    "BILL": "结账",
    "CONCIERGE": "门房",
    "DIRECTORY": "目录",
    "SETTINGS": "语言",
    "FILTERS": "Dietery Filters",
    "BRANDS": "Brands",
    "SECTIONS": "Sections"
  },
  "SETTINGS": {
    "TITLE": "设置",
    "LANGUAGE": "语言",
    "CURRENCY": "Currency"
 },
  "HOTEL_PREFERENCE": {
    "TITLE": "请按需选择",
    "ROOM": "消费计入房费",
    "DELIVERY": "立即结算",
    "NUMBER_OF_GUESTS": "Cutlery for "
  },
  "RESCAN": {
    "TITLE": "QR",
    "ACTION": "Please rescan the QR to continue your session",
    "RESCAN": "Rescan",
    "SCANNING": "Scanning..."
  },
  "PICKUP": {
    "TITLE": "Your Details"
  },
  "VOUCHER": {
    "ADD": "Add a Voucher",
    "VOUCHER": "Voucher",
    "DISCOUNTED_AMOUNT": "Total After Discount",
    "CURRENT_BALANCE": "Balance",
    "REMAINING_BALANCE": "Balance After Purchase",
    "AMOUNT_DUE": "Amount Due",
    "WARNING": "Voucher can only be applied once per bill"
  }
}
